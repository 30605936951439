<template>
  <!--
    The view for the Event Calendar
  -->
  <Portlet
    :title="$t('eventCalendar')"
    icon="calendar-plus"
  >
    <template slot="buttons">
      <font-awesome-icon
        :class="['mr-3 alt-pointer color-primary', { 'fa-spin' : loading}]"
        icon="sync-alt"
      />
      <button
        class="btn btn-primary btn-sm ml-2 float-right"
        @click="openSidebar()"
      >
        <span>
          {{ $t('add') }}
        </span>
      </button>
    </template>
    <div class="row">
      <LoadingPlaceholder v-if="loading" />
      <template v-else>
        <div
          v-for="(eType, index) in eventTypes"
          :key="`li-item${ index }`"
          class="event-legend ml-3 row"
          @click="setEventType(eType)"
        >
          <div
            :style="'background-color:' + eType.color + ';'"
            class="mt-1"
          />
          <p :class="['ml-2 mr-4', {'selected-type' : excludedTypes.some(x => eType.name == x.name)}]">
            {{ eType.name }}
          </p>
        </div>
      </template>
    </div>
    <EventCalendar
      ref="eventCalendar"
      :excluded-types="excludedTypes"
      :event-types="eventTypes"
      @setLoading="setLoading"
      @setEventTypes="setEventTypes"
    />
  </Portlet>
</template>

<script>
export default {
  name: "EventCalendarView",
  components: {
    EventCalendar: () => import('@/components/OnCallDuty/EventCalendar.vue'),
  },
  data () {
    return {
      eventTypes: [],
      excludedTypes: [],
      loading: false
    }
  },
  metaInfo () {
    return {
      title: this.$t('eventCalendar')
    }
  },
  watch: {
    eventTypes (val) {
      this.excludedTypes = this.excludedTypes.filter(x => val.some(t => t.name == x.name))
    }
  },
  methods: {
    openSidebar() {
      this.$refs.eventCalendar.handleDateSelect()
    },
    setLoading (val) {
      this.loading = val;
    },
    setEventTypes (val) {
      this.eventTypes = val.sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
    },
    setEventType (val) {
      if(this.excludedTypes.some(x => x.name == val.name)) {
        this.excludedTypes = this.excludedTypes.filter(item => item.name !== val.name)
      }
      else {
        this.excludedTypes.push(val);
      }
    }
  }
}
</script>

<style scoped>
.event-legend p{
  font-size: 15px
}

.event-legend {
  cursor: pointer;
}

.event-legend p.selected-type {
  text-decoration: line-through;
  font-weight: bold;
}

.event-legend div {
  width: 15px;
  height: 15px;
  border-radius: 10px;
}
</style>